import { render, staticRenderFns } from "./TheHomeRight.vue?vue&type=template&id=9c19c720&scoped=true"
import script from "./TheHomeRight.vue?vue&type=script&lang=ts&setup=true"
export * from "./TheHomeRight.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c19c720",
  null
  
)

export default component.exports