import { render, staticRenderFns } from "./TheHomeLeftTop.vue?vue&type=template&id=7c66504c&scoped=true"
import script from "./TheHomeLeftTop.vue?vue&type=script&lang=ts&setup=true"
export * from "./TheHomeLeftTop.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c66504c",
  null
  
)

export default component.exports